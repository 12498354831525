'use client'
import { usePathname, useSearchParams } from 'next/navigation'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import * as pixel from './fpixel.js'
import Cookies from 'js-cookie'
import { getDomain } from '@/utils/cookies'

declare global {
    interface Window {
        fbq: (eventType: string, eventName: string, eventProperties?: object, options?: { eventID: string }) => void
    }
}

const FacebookPixelListener = () => {
    const [loaded, setLoaded] = useState(false)
    const pathname = usePathname()
    const searchParams = useSearchParams()
    const fbclid = searchParams?.get('fbclid')

    const shouldSetFBCCookie = (currentFbclid: string): boolean => {
        const existingFBC = Cookies.get('_fbc')

        if (!existingFBC) {
            return true // Set cookie if it doesn't exist
        }

        // Extract fbclid from existing cookie
        // Cookie format: fb.1.{timestamp}.{fbclid}
        const existingFbclid = existingFBC.split('.').pop()

        // Set cookie if fbclid values don't match
        return existingFbclid !== currentFbclid
    }

    useEffect(() => {
        if (!loaded) return

        pixel.pageview()
    }, [pathname, loaded])

    useEffect(() => {
        if (fbclid && shouldSetFBCCookie(fbclid)) {
            const value = `fb.1.${Date.now()}.${fbclid}`
            console.log('setting fbc cookie to: ', value)
            Cookies.set('_fbc', value, {
                expires: 90,
                domain: getDomain(),
                sameSite: 'Lax',
            })
        }
    }, [pathname, fbclid])

    return (
        <div>
            <Script id="fb-pixel" src="/js/pixel.js" strategy="afterInteractive" onLoad={() => setLoaded(true)} data-pixel-id={process.env.NEXT_PUBLIC_FB_PIXEL_ID} />
        </div>
    )
}

export default FacebookPixelListener
