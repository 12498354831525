'use client'

import { useSearchParams } from 'next/navigation'
import { Suspense, useEffect } from 'react'
import Cookies from 'js-cookie'
import { getDomain } from '@/utils/cookies'

const COOKIE_NAME = 'promo'
const COOKIE_EXPIRES = 90 // days

const cookieOptions = {
    expires: COOKIE_EXPIRES,
    domain: getDomain(),
    sameSite: 'Lax' as const,
}

const PromoTrackerInner = () => {
    const searchParams = useSearchParams()

    useEffect(() => {
        const value = searchParams?.get(`promo`)
        if (!value) return

        Cookies.set(COOKIE_NAME, value, cookieOptions)

        // const promoValue = Cookies.get(COOKIE_NAME)
        // console.debug('Current promo cookie value:', promoValue)
    }, [searchParams])

    return null
}

const PromoTracker = () => {
    return (
        <Suspense>
            <PromoTrackerInner />
        </Suspense>
    )
}

export default PromoTracker
